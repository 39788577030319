import React from 'react'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'

class boilerplateEntities extends React.Component {
  render() {
    return (
      <MDBContainer className="text-small">
        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicomglobal.com" target="_blank">www.unicomglobal.com</a>
            <p className="mb-0 mt-1">UNICOM Global – Vermögens-, Kapital- und Investitionsmanagement</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicomsi.com" target="_blank">www.unicomsi.com</a>
            <p className="mb-0 mt-1">UNICOM Systems – IBM Mainframe Software-Produkte</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicomgov.com" target="_blank">www.unicomgov.com</a>
            <p className="mb-0 mt-1">UNICOM Government (formerly NASDAQ: GTSI) – IT-Lösungen für Behörden</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicomengineering.com" target="_blank">www.unicomengineering.com</a>
            <p className="mb-0 mt-1">UNICOM Engineering (formerly NASDAQ: NEI) – Appliance-Plattformen</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicom.org/" target="_blank">www.unicom.org</a>
            <p className="mb-0 mt-1">Wissenschafts- und Technologie-Parks von UNICOM</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicomtechnologypark.com" target="_blank">www.unicomtechnologypark.com</a>
            <p className="mb-0 mt-1">UNICOM Technologie-Park – Innovation Labs in Virginia</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicomsciencepark.com" target="_blank">www.unicomsciencepark.com</a>
            <p className="mb-0 mt-1">UNICOM Wissenschafts- und Technologie-Park – Innovation Labs in New Jersey</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicom-capital.com" target="_blank">www.unicom-capital.com</a>
            <p className="mb-0 mt-1">UNICOM Capital – Business- und Finanz-Dienstleistungen</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.usrobotics.com" target="_blank">www.usrobotics.com</a>
            <p className="mb-0 mt-1">USRobotics – Produkte für Datenkommunikation</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.memeo.com" target="_blank">www.memeo.com</a>
            <p className="mb-0 mt-1">Memeo – Unternehmensweites, sicheres Datei-Sharing in der Cloud</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.firetide.com" target="_blank">www.firetide.com</a>
            <p className="mb-0 mt-1">Firetide – Drahtlose Technologie-Lösungen für Sicherheit und Verkehrswesen</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.detec.com" target="_blank">www.detec.com</a>
            <p className="mb-0 mt-1">DETEC – Produkte für Dokumentengestaltung</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.softlanding.com" target="_blank">www.softlanding.com</a>
            <p className="mb-0 mt-1">SoftLanding Systems – IBM i Software-Produkte</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.macro4.com" target="_blank">www.macro4.com</a>
            <p className="mb-0 mt-1">Macro 4 (formerly LONDON: MAO) – Produkte für Dokumentenmanagement</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.illustro.com" target="_blank">www.illustro.com</a>
            <p className="mb-0 mt-1">illustro – z/OS und z/VSE Software-Produkte</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.iet-solutions.com" target="_blank">www.iet-solutions.com</a>
            <p className="mb-0 mt-1">iET Solutions – ITSM Software-Produkte</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.eden.com" target="_blank">www.eden.com</a>
            <p className="mb-0 mt-1">Eden - Mergers &amp; Acquisitions, Immobilien und Finanzdienstleistungen</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.cics.com" target="_blank">www.cics.com</a>
            <p className="mb-0 mt-1">CICS.com – Hardware, Software, Outsourcing und Professional Services</p>
          </MDBCol>
        </MDBRow>

       <p className="text-extra-small mt-5"> Alle hier erwähnten Marken sind Marken der jeweiligen Unternehmen.</p>
      </MDBContainer>
    )
  }
}

export default boilerplateEntities